import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VMain,[_c('div',{staticClass:"center"},[_c(VCard,{attrs:{"max-width":"300","elevation":"20"}},[_c(VCardTitle,{staticClass:"mb-0 pb-0 p-3"},[_c(VImg,{attrs:{"src":_vm.merchantData.logo}})],1),_c(VCardTitle,{staticClass:"mt-0 pt-0"},[_vm._v(" "+_vm._s(_vm.merchantData.name)+" Login ")]),_c(VCardActions,[_c(VTextField,{attrs:{"outlined":"","dense":"","label":"Usuário","hide-details":"auto"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c(VCardActions,[_c(VTextField,{attrs:{"outlined":"","dense":"","label":"Senha","hide-details":"auto","type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"disabled":_vm.isLoggingIn,"loading":_vm.isLoggingIn,"color":"primary","block":""},on:{"click":_vm.login}},[_vm._v(" Entrar ")])],1)],1)],1),_c(VFooter,{attrs:{"absolute":""}},[_c('div',{staticClass:"text-center",staticStyle:{"width":"100%"}},[_vm._v(" © metrics.center 2022 - Build: "+_vm._s(_vm.version)+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }